<template>
  <!-- 扣分明细 -->
  <div id="deduction">
    <TopBar class="background topTitle">
      <template v-slot:leftInfo>
        <svg @click="toLastIndex" t="1601170867949" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4264" width="24" height="24">
          <path d="M608.992 148.992L277.984 489.984q-8.992 8.992-8.992 21.504t8.992 22.496l331.008 340.992q8.992 8 20.992 8t20.992-8.992 8.992-20.992-8.992-20.992l-312-320 312-320q8.992-8.992 8.992-20.992t-8.992-20.992-20.992-8.992-20.992 8z" p-id="4265" fill="#ffffff"></path>
        </svg>
      </template>
      <template v-slot:middleInfo>
        <span class="topTitle">扣分明细</span>
      </template>
      <template v-slot:rightInfo></template>
    </TopBar>
    <div class="deductionInfo">
      <p class="infoTitle">
        <span class="fBold">重置质量分</span>
        <span class="cRed fBold">90分</span>
      </p>
      <p class="infoTimer">2019-12-05 07:04:06</p>
      <p class="infoTimer">系统自动</p>
    </div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
export default {
  components: {
    TopBar,
  },
  methods: {
    toLastIndex() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less">
#deduction {
  background: #fff;

  .deductionInfo {
    margin: 45px auto;
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #fafafa;
    p {
      padding-bottom: 1rem;
    }
    .infoTitle {
      display: flex;
      justify-content: space-between;
      font-size: 1.6rem;
      .cRed {
        color: red;
      }
      .fBold {
        font-weight: 600;
      }
    }
    .infoTimer {
      color: #9c9c9c;
      font-size: 1.4rem;
    }
  }
}
</style>>